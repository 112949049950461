@import "../App.scss";

section {
}

ul {
  list-style: none;
}

p {
  margin-bottom: 0.5em;
}

.btn {
  outline: none;
  border: 0px;
  color: $white;
  padding: 10px 40px;
  cursor: pointer;
  height: 48px;
  &:hover {
    opacity: 0.9;
  }
}

section.introducing-esquity {
  padding-top: 130px;
  background: $background;
  position: relative;
  min-height: 100vh;
  text-align: center;
  .container {
    width: 78%;
    margin: auto;
    .success {
      position: absolute;
      bottom: -100px;
      left: 150px;
    }
    button.gradient-button {
      background: $text-heading;
      box-shadow: 0px 12px 24px rgba(242, 78, 36, 0.2);
      border-radius: 100px;
      padding: 0 1.4em;
      .gradient-text {
        background: linear-gradient(90deg, #ff5858 0%, #f09819 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        padding: 0.3em 0.5em;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
    }

    h1.title {
      font-size: 45px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      position: relative;
      .ellipse {
        position: absolute;
        width: 165px;
        height: 66px;
      }
    }
    p.subtitle {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      margin-bottom: 50px;
      line-height: 32px;
    }
    .notify {
      width: 65%;
      margin: auto;
      text-align: left;
      p {
        color: $text-subtext;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        font-family: SF UI Text;
      }
      span.input-button {
        display: flex;
        input {
          padding: 0.5em;
          width: 80%;
          height: 50px;
          border: 1px solid $primary-color;
          border-radius: 5px 0px 0px 5px;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
        }
        button.btn-primary {
          background: $primary-color;
          border-radius: 0px 4px 4px 0px;
          height: 50px;
          width: 20%;
          padding: 0.68em 1em;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
  }
}

section.how-it-works {
  padding-top: 100px;
  padding-bottom: 100px;
  .container {
    width: 75%;
    margin: auto;
    text-align: center;
    p.how-it-works-subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: $primary-color;
      margin-bottom: 0;
    }
    p.how-it-works-title {
      color: $text-heading;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      margin-top: 0;
    }
    div.investors-entrepreneurs {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      .left {
        box-shadow: 0px 4px 20px rgba(52, 37, 33, 0.1);
      }
      .left,
      .right {
        width: 49%;
        // border: 1px solid;
        padding: 2em;
        .how-it-works-subtitle {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          color: $primary-color;
          text-align: left;
          padding-left: 60px;
        }
        .how-it-works-list {
          .list {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            .icon {
              text-align: left;
            }
            .group {
              text-align: left;
              margin-left: 50px;
              .group-title {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                color: $text-heading;
              }
              .group-subtitle {
                color: $text-subtext;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  section.introducing-esquity {
    padding-top: 130px;
    padding-bottom: 20px;
    .container {
      width: 90%;
      margin: auto;
      .success {
        display: none;
      }

      h1.title {
        span {
          display: inline-block;
          .ellipse {
            position: absolute;
            width: 165px;
            height: 66px;
          }
        }
      }
      p.subtitle {
      }
      .notify {
        width: 100%;

        p {
          margin-top: 20px;
          font-size: 15px;
        }
        span.input-button {
          input {
            width: 70%;
          }
          button.btn-primary {
            font-size: 14px;
            width: 30%;
          }
        }
      }
    }
  }
  section.how-it-works {
    padding-top: 50px;
    .container {
      width: 95%;
      p.how-it-works-subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: $primary-color;
        margin-bottom: 0;
      }
      p.how-it-works-title {
        color: $text-heading;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        margin-top: 0;
      }
      div.investors-entrepreneurs {
        display: flex;
        flex-direction: column;
        align-items: center;
        .left,
        .right {
          width: 90%;
          padding: 0.2em;
          .how-it-works-subtitle {
            padding-left: 10px;
          }
          .how-it-works-list {
            padding-left: 4px;
            .list {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .icon {
                text-align: left;
                svg {
                  height: 36px;
                  width: 36px;
                }
              }
              .group {
                text-align: left;
                margin-left: 20px;
                .group-title {
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                }
                .group-subtitle {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
