@import "../App.scss";

.header {
  height: 100px;
  position: absolute;
  width: 100%;
  z-index: 1;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 0.5em 4em;
  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      flex: 1;
    }
    .nav-links {
      display: flex;
      list-style: none;
      flex: 1;
      justify-content: flex-end;
      .link {
        padding-right: 1.3em;
      }
      .instagram {
        display: flex;
        align-items: center;
      }
      .linkedin {
        // max-width: 14px;
        // max-height: 19px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 0.5em 2em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
