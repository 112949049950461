// @import '~antd/dist/antd.css';
// Utilities
@import "./utilities/theme";

* {
  box-sizing: border-box;
  transition: all ease-in-out 250ms;
  font-family: "SF UI Text", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

// For input field outlines
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

// Mixins definations
@mixin label-default() {
  width: 100%;
  font-weight: 600;
  margin-top: 0.4em;
  padding: 0.3em 0.2em;
}

@mixin flex-row-display() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-col-display() {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body-container {
  min-height: 100vh;
  width: 100%;
  // overflow: hidden;
}

@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
