@import "./fonts";

$white: #fff;
$black: #000;
$grey: #d9dbe1;
$primary-color: #f24e24;

$text-heading: #110401;
$text-subtext: #333333;
$background: #fce9e4;

$linear-gradient: linear-gradient(90deg, #ff5858 0%, #f09819 100%);

// ----------------------------------- Fonts ------------------------------------------
$font-sf-pro: "SF Pro Text", sans-serif;
