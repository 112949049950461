@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
// font-family: 'Nanum Pen Script', cursive;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap");
// font-family: 'Source Sans Pro', sans-serif;

@font-face {
  font-family: "SF Pro Text";
  src: url("../assets/fonts/SFProText-Regular.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/SFProText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/sf-pro-text-regular-webfont.woff2") format("woff2"),
    /* Super Modern Browsers */ /* IE6-IE8 */
      url("../assets/fonts/FontsFree-Net-SFProText-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "SF UI Text";
  src: url("../assets/fonts/SFUIText-Regular.woff") format("woff");
}
