@import "../App.scss";

.footer {
  position: relative;
  overflow-x: hidden;
  .leaf {
    position: absolute;
    height: 350px;
    width: 390px;
  }
  .bottom-leaf {
    right: -50px;
  }
  p {
    margin-top: 0px;
  }
  ul {
    list-style: none;
    padding-left: 0px;
  }
  min-height: 400px;
  border: 1px solid black;
  background: $text-heading;
  color: $grey;
  .container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 2em;
    .top {
      display: flex;
      // justify-content: space-between;
      padding-bottom: 20px;
      .esquity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        margin-right: 50px;
        .logo {
          margin-bottom: 0.5em;
        }
        .text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .socials {
          .nav-links {
            display: flex;

            .link {
              .social-icon {
                padding-right: 10px;
                width: 35px;
                height: 35px;
                path {
                  fill: white;
                  opacity: 0.6;
                }
              }
            }
          }
        }
      }
      .linkss {
        flex: 4;
        display: flex;
        justify-content: space-between;
        padding-right: 150px;
        position: relative;
        z-index: 2;
        .investors,
        .entrepreneur,
        .company {
          // margin-right: 50px;
          text-align: left;
          .title {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
          }
          .links {
            .link {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 12px;
              cursor: pointer;
              span {
                font-family: "Nanum Pen Script", cursive !important;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                color: $primary-color;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .horizontal-line {
      width: 100%;
      margin: auto;
      border: 1px solid rgba(255, 255, 255, 0.32);
    }
    .bottom {
      padding-top: 20px;
      font-family: Manrope;
      p.text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    .container {
      .top {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .esquity {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .text {
            font-size: 18px;
          }
        }
        .linkss {
          display: flex;
          flex-direction: column;
          padding-right: 50px;
          .investors,
          .entrepreneur,
          .company {
            margin-bottom: 10px;
            .title {
              font-size: 24px;
            }
            .links {
              .link {
                font-size: 18px;
                span {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .horizontal-line {
        width: 100%;
        margin: auto;
        border: 1px solid rgba(255, 255, 255, 0.32);
      }
      .bottom {
        p.text {
          font-size: 18px;
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
